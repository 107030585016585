import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const NowPlaying = () => {
  // const data = useStaticQuery(graphql`
  //   query SongQuery {
  //     allSpotifyRecentTrack(limit: 1) {
  //       edges {
  //         node {
  //           id
  //           track {
  //             artistString
  //             name
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      {/* <span>
        {data.allSpotifyRecentTrack.edges[0].node.track.name} -{" "}
        {data.allSpotifyRecentTrack.edges[0].node.track.artistString}
      </span> */}
    </>
  )
}

export default NowPlaying
