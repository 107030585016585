import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NowPlaying from "../components/nowplaying"
import FriendsTab from "../components/friendstabs"
import { StaticImage } from "gatsby-plugin-image"
import bgBackground from "../images/profilbg.png"

const IndexPage = () => (
  <Layout>
    <div
      style={{ backgroundImage: `url("` + bgBackground + `")` }}
      className="w-full h-max p-5 shadow-md"
    >
      <div className="bg-white rounded-lg">
        <div className="bg-tabs text-white p-2 font-bold rounded-t-lg">
          <span className="drop-shadow-lg">Mauran Muthiah</span>
        </div>

        <Seo title="Profil" />

        <div className="grid grid-cols-6">
          <div className="col-span-2 p-2">
            <StaticImage
              className="rounded-lg"
              src="../images/me.jpeg"
            ></StaticImage>
          </div>
          <div className=" col-span-4">
            <div class="relative overflow-x-auto p-1">
              <table class=" text-sm text-left w-full">
                <tbody>
                  <tr class="border-dashed border-b hover:bg-gray-50">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium  whitespace-nowrap"
                    >
                      Seneste tweet
                    </th>
                    <td class="px-6 py-4">
                       <a href="#">UDP protokollen kan beskrives som at sende pakker med DAO. Man sender en pakke afsted også håber man lidt på det bedste.</a>
                    </td>
                  </tr>

                  <tr class="border-dashed border-b hover:bg-gray-50">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium  whitespace-nowrap"
                    >
                      Now Playing
                    </th>
                    <td class="px-6 py-4">
                      <a href="https://open.spotify.com/track/5C2mUkFTs51bizl4LAaqRy?si=b137cd344ab940e6" className="text-red-600 font-bold">
                        Run Intro Trouble - Alok, Bastile
                      </a>
                    </td>
                  </tr>


                  <tr class="border-dashed border-b hover:bg-gray-50">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium  whitespace-nowrap"
                    >
                      Sted
                    </th>
                    <td class="px-6 py-4">Tranbjerg, Aarhus</td>
                  </tr>

                  <tr class="border-dashed border-b hover:bg-gray-50">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium  whitespace-nowrap"
                    >
                      Arbejde
                    </th>
                    <td class="px-6 py-4">IBM Client Innovation Center</td>
                  </tr>

                  <tr class="border-dashed border-b hover:bg-gray-50">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium  whitespace-nowrap"
                    >
                      Links
                    </th>
                    <td class="px-6 py-4">
                      <a href>Github, Twitter, Instagram, Facebook, Snapchat</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <NowPlaying></NowPlaying>
      </div>

      <div className="bg-white rounded-md mt-4 shadow-md">
        <div className="bg-tabs text-white rounded-t-md">
          <div className="p-2">
            <span className="drop-shadow-lg font-bold">Profiltekst</span>
          </div>
        </div>

        <div className="bg-bio p-5 text-xl text-white">
          <p>Hej og velkommen til min ✨personlige hjemmeside✨</p>
          <p>
            Hej
          </p>
          <p>Projekter</p>
          <p>API-Danmark</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
